/**
 * Interface Provider {
    * id: string
    * name: string
    * imageUrl: string
    * description: string
    * specialization: string
    * specialtiesList: string[]
    * educationList: string[]
 *  }
 */

const daryl_carlson = {
    id: 'daryl-carlson',
    name: 'Daryl Carlson, CFO',
    imageUrl: '\\assets\\images\\headshots\\Carlson Daryl.jpg',
    description: 'CFO',
    specialization: 'Finances, office tasks, scheduling',
    specialtiesList: [],
    educationList: ['Experience as a project manager and COO of Reinforement consulting,']
}

const julia_rose = {
    id: 'julia-rose',
    name: 'Julia Rose',
    imageUrl: '\\assets\\images\\headshots\\rosejuliaheadshot.jpg',
    description: 'Executive Assistant and Receptionist',
    specialization: 'Office tasks, scheduling, reception',
    specialtiesList: [],
    educationList: ['BS Biochemistry',]
}
/* const brenna_harms = {
    id: 'brenna-harms',
    name: 'Brenna Harms',
    imageUrl: '\\assets\\images\\updatedpics\\brennaHeadshot.png',
    description: 'Executive Assistant and Masters students',
    specialization: 'Office tasks, training',
    specialtiesList: [],
    educationList: []
} */

const ali_carlson = {
    id: 'ali-carlson',
    name: 'Ali Carlson',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\NewHeadshots\\Ali Head Shot w BG.jpg',
    description: 'Executive Assistant',
    specialization: 'office tasks, scheduling, reception',
    specialtiesList: [],
    educationList: ['BS Biology Berry College',]
}

const artie = {
    id: 'artie',
    name: 'Artemis (Artie)',
    imageUrl: '\\assets\\images\\updatedpics\\artiePic.jpg',
    description: 'Artemis is a French Bulldog puppy who has her own personality and attitude. She loves to explore the office and make sure she is not missing opportunities to meet everyone, get as many dog friendly snacks as possible, and play with her friend Kenai. Artie loves to sleep and play and enjoys spending time at the office with Alex and the people who come visit.',
    specialization: 'Therapy Dog',
    specialtiesList: [
        'Lowering blood pressure',
        'Improving cardiovascular health',
        'Releasing calming endorphins (oxytocin)',
        'Lowering overall physical pain',
        'Producing automatic relaxation response',
        'Lifting spirits and lessening depression',
        'Lowering feelings of isolation and alienation',
        'Encouraging communication',
        'Providing comfort',
        'Increasing socialization',
        'Reducing anxiety',
        'Aiding children in overcoming speech and emotional disorders',
        'Creating motivation for faster recovery'
    ],
    educationList: [
        'Certified Good Girl',
        'Professional Snuggler',
        'Expert in Canine Comfort Techniques'
    ]
}

const kenai = {
    id: 'kenai',
    name: 'Kenai',
    imageUrl: '\\assets\\images\\pagePhotos\\kenaiNature.jpg',
    description: "Hi! My name is Kenai and I am a 5-year-old Norwegian Elk Hound. I have been coming to work since I was a puppy but then COVID happened and no one came to the office. Now that we are in the office again, I can't wait for people to come in! I am currently through my first set of therapy dog classes and I also have my CISM certificate so I'll be working with our first responders too!",
    specialization: 'Therapy Dog',
    specialtiesList: [
        'Lowering blood pressure',
        'Improving cardiovascular health',
        'Releasing calming endorphins (oxytocin)',
        'Lowering overall physical pain',
        'Producing automatic relaxation response',
        'Lifting spirits and lessening depression',
        'Lowering feelings of isolation and alienation',
        'Encouraging communication',
        'Providing comfort',
        'Increasing socialization',
        'Reducing anxiety',
        'Aiding children in overcoming speech and emotional disorders',
        'Creating motivation for faster recovery'
    ],
    educationList: [
        'First set of therapy dog classes',
        'CISM certificate'
    ]
}

export const staff = [
    daryl_carlson,
    ali_carlson,
    julia_rose,
    
    artie, kenai
];

export const staffMap = staff.reduce((map, provider) => {
    map[provider.id] = provider;
    return map;
}, {});
